
import { Col, Row } from "antd";
import { useControl } from "@essenza/react";

function CameraViewControl(c) {
  c.skin = CameraView;
  c.command = {
    ZOOM: (el) => {
      c.openPopup(el, "Webcam", window.screen.width - 80);
    }
  }
}

export function CameraView({ satellite, settings }) {

  const [control] = useControl(CameraViewControl);

  const mirova = satellite.data;

  if (!settings || !settings.UrlCamera) return null;

  const img = settings.UrlCamera + new Date().toTimeString();

  return (
    <Row style={{ height: 'calc((100vh - 108px)/5)' }} >
      {/*<Col span={2}></Col>
      <Col flex="none"><img onClick={(e) => control.execute("ZOOM", <img style={{ maxWidth: window.screen.width - 160 }} src={e.target.src} alt="Cam screenshot" />)} src={img} style={{ height: 'calc((100vh - 108px)/5)', width: 'auto', cursor: 'pointer' }} className="my-auto border-round ew-img" alt="Etna Cam screenshot"></img></Col>
      <Col span={1}></Col>
      <Col flex="none" className="d-flex justify-content-center" >
        {mirova
          ? <div className="over-container">
              <img style={{ width: '100%' }} src={settings.UrlModis + mirova.path} className="ew-img px-2 my-auto border-round" alt="Etna satellite"></img>
              <div className="over-top-left">{mirova ? new Date(mirova.time).toLocaleString() : ""}</div>
            </div>
          : null}
      </Col>*/}
    </Row>
  )
}
