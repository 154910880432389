import { useEffect} from 'react';
import { RealTimeData} from '@essenza/core';
import { useGraph, useControl } from '@essenza/react';

import { useEChart } from '../../hook/chartHook';
import EChart from '../../components/chart/chart';
import Title from '../../components/chart/option/title';
import Legend from '../../components/chart/option/legend';
import Tooltip from '../../components/chart/option/tooltip';
import Series from '../../components/chart/option/series/series';
import Features from '../../components/chart/option/feature/features';
import ExportImage from '../../components/chart/option/feature/image';
import { ChannelService } from '../../service/ChannelService';
import Line from '../../components/chart/option/series/Line';
import ValueAxis from '../../components/chart/option/axis/ValueAxis';
import CategoryAxis from '../../components/chart/option/axis/CategoryAxis';
import XAxis from '../../components/chart/option/axis/xAxis';
import YAxis from '../../components/chart/option/axis/yAxis';

function ChannelRTControl(c) {
    c.skin = ChannelRT;
}

export function ChannelRT({ token }) {
    const [control] = useControl(ChannelRTControl);
    const chart = useEChart();
    const source = useGraph("sensor.jrealtime");
    const data = source.data;

    useEffect(() => {
        let rt;
        if (data) {
            if (chart.instance) {
                rt = new RealTimeData(chart.instance, data, (name, interval) => control.request(ChannelService, s => s.getData(name, interval)));
                rt.init();
            }
        }
        return () => {
            if (rt) rt.stop();
        }
    }, [data])

    return (
        <EChart chart={chart} className="channel-h">
            <Title left="left" text="Sensor data" textStyle={{ fontSize: 15 }} />
            <Legend padding={7} data={['value']} />
            <Tooltip trigger="axis" />
            <XAxis>
                <CategoryAxis />
            </XAxis>
            <YAxis>
               <ValueAxis scale={true} nameLocation='end' nameTextStyle={{ align: 'left', fontWeight: 'bold' }} /> 
            </YAxis>
            <Series>
                <Line name="value" showSymbol={false} smooth={true} />
            </Series>
            <Features>
                <ExportImage title="SALVA IMMAGINE" excludeComponents={['dataZoom', 'toolbox']} />
            </Features>
        </EChart>
    )
}

export function ChannelUtil() {
    this.getTraceId = (sensor) => sensor.trace_id.replaceAll('_', '.');
    this.getTraceList = (sensors) => {
        let name = "", trace = [];
        for (let k = 0; k < sensors.length; k++) {
            if (sensors[k].status < 1) continue;
            trace.push(this.getTraceId(sensors[k]));
            if (k === 0)
                name += sensors[k].trace_id;
            else
                name += '|' + sensors[k].trace_id;
        }
        return [name, trace];
    };

    this.formatData = (sensor, data, time) => {
        const traceid = this.getTraceId(sensor);
        return { time: time, name: sensor.trace_id, trace: [sensor.trace_id.replaceAll('_', '.')], source: [data[traceid][0].data], rate: data[traceid][0].sampling_rate };
    };

    this.formatDataSet = (sensors, data, trace, name, time) => {
        const source = [];
        let z = 0;
        for (let j = 0; j < sensors.length; j++) {
            if (sensors[j].status < 1) continue;
            source.push(data[trace[z]][0].data);
            z++;
        }
        return { time: time, name: name, trace: trace, source: source, rate: data[trace[0]][0].sampling_rate }
    }
}