import { Col, Progress, Row } from "antd";
import { useEffect, useState } from "react";
import { useEChart } from '../../hook/chartHook';
import { StatusData, StatusStyle } from "./statusOption";
import EChart from '../../components/chart/chart';
import Series from "../../components/chart/option/series/series";
import Gauge from "../../components/chart/option/series/Gauge";

export function StatusBar({source}) {
  const sdata = source.data;

  const [statusData, setStatus] = useState();

  const chart = useEChart(StatusStyle.option);

  useEffect(() => {
    if(chart.instance && sdata){
      chart.instance.setOption(StatusData.format(sdata));
    }
    if (sdata)
      setStatus(sdata);
  }, [sdata])

  return (
    <>
      <h2 style={{marginTop: '8px'}} className="text-center text-white">System Status</h2>
      <EChart chart={chart} style={{ height: '126px', width: '220px', display: 'block', margin: 'auto' }}  >
        <Series>
          <Gauge height={140} width={180} startAngle={180} endAngle={0} min={0} max={100} splitNumber={1} center={['50%', '40%']} 
            itemStyle={StatusStyle.itemStyle} progress={StatusStyle.progress} pointer={StatusStyle.pointer} title={StatusStyle.title} detail={StatusStyle.detail} 
            data={StatusData.value} />
        </Series>
      </EChart>
      {statusData &&
        <>
          <h2 style={{marginTop: '8px'}} className="text-center text-white">Infrasonic Activity</h2>
          <Row justify="space-around" >
            <Col ><Progress type="circle" status="success" className="mb-2" width={90} percent={Math.trunc(statusData.signal['N. of detections'])} format={percent => `${percent} %`} /></Col>
          </Row>
          <Row justify="space-around" className="text-center smaller" >
            <Col span={10} className="border-round" style={{ backgroundColor: '#cccccc', }}>
              <h2><b>Mean Pressure</b></h2>
              <h3>{statusData.signal['Average signal level'] + ' Pa'}</h3>
            </Col>
            <Col span={10} className="border-round" style={{ backgroundColor: '#cccccc', }}>
              <h2><b>Max Pressure</b></h2>
              <h3>{statusData.signal['Max signal level'] + ' Pa'}</h3>
            </Col>
          </Row>
          <h2 className="text-center text-white mt-sm">Infrasonic Level</h2>
          <Row justify="space-around" className="text-center smaller" >
            <Col span={10} className="border-round" style={{ backgroundColor: '#cccccc',  }}>
              <h2><b>Mean Pressure</b></h2>
              <h3>{statusData.infrasonic['Signal + Noise level'] + ' Pa'}</h3>
            </Col>
            <Col span={10} className="border-round" style={{ backgroundColor: '#cccccc', }}>
              <h2><b>Max Pressure</b></h2>
              <h3>{statusData.infrasonic['Infrasonic level max.'] + ' Pa'}</h3>
            </Col>
          </Row>
        </>}
    </>
  );
}

export function SatusView() {
  return (null);
}