import { Outlet, useLocation } from "react-router-dom";
import { Col, Layout, Row, Spin} from "antd";
import logoLgs from "../assets/img/logo_lgs.png";

import {ReactComponent as Ew0} from "../assets/img/ew0m.svg";
import {ReactComponent as Ew1} from "../assets/img/ew1m.svg";
import {ReactComponent as Ew2} from "../assets/img/ew2m.svg";
import {useGraph} from "@essenza/react";
import { useEffect, useRef } from "react";

import { MenuOutlined, CloseOutlined } from "@ant-design/icons";

const { Header, Content, Footer } = Layout;

export function MobileLayout({ token }) {
  const source = useGraph("system.status");
  const data = source.data;
  const control = token.current?.control;
  const location = useLocation();
  const path = useRef("mobilemenu");
  let state = <Spin tip="Loading Stato Allerta..." className="my-auto" />;//<Ew0  className="my-auto"/>;//
  if(data && data.hasOwnProperty("state"))
  state = [<Ew0  className="my-auto"/>, <Ew1 className="my-auto"/>, <Ew2 className="my-auto"/>][data.state]; 
  
  useEffect(()=>{  
    if(data && document.getElementById("ew-updated")) {
      if(data.status && data.status.length > 0)
      document.getElementById("ew-updated").textContent = new Date(data.time+'Z').toLocaleString();//data.time;
    }    
  },[data])

  return (
    <Layout className="layout">
      <Header className="header-mobile">
        <Row>
            <Col flex="auto"><img src={logoLgs} height="44" className="my-auto" alt="Logo Laboratorio Geofisica"></img></Col>
            <Col flex="none" className="pointer" >{
            location.pathname === "/mobilemenu"
            ?<CloseOutlined  onClick={()=> {control.navigate("/home")}} style={{fontSize: '2em'}} />
            :<MenuOutlined onClick={()=> {path.current = location.pathname; control.navigate("/mobilemenu")}} style={{fontSize: '2em'}} />
        }</Col>
        </Row>
      </Header>
      <Header className="header-top">
        <div style={{fontSize: '0.8em'}} className="font-bold">Infrasonic Early Warning of Lava Fountains</div>
      </Header>
      <Content className="layout-bg layout-content">
        <Outlet></Outlet>
      </Content>
      <Footer style={{height: '64px', backgroundColor: 'black', padding: '4px 0'}}>{<div className="centered"  style={{cursor: 'pointer', width: 'min-content'}} onClick={()=> control.navigate("/mobilew")}>{state}</div>}</Footer>
    </Layout>
  );
}