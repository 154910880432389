import { useEffect, useRef, useState } from 'react';
import { BigData } from '@essenza/core';
import { useGlobal } from '@essenza/react';

import EChart from '../components/chart/chart';
import { useEChart } from '../hook/chartHook';
import Legend from '../components/chart/option/legend';
import Tooltip from '../components/chart/option/tooltip';
import TimeAxis from '../components/chart/option/axis/TimeAxis';
import Series from '../components/chart/option/series/series';
import ZoomSlider from '../components/chart/option/zoom/Slider';
import ZoomInside from '../components/chart/option/zoom/Inside';
import Features from '../components/chart/option/feature/features';
import ExportImage from '../components/chart/option/feature/image';
import ExportCSV from '../components/chart/option/feature/csv';
import Line from '../components/chart/option/series/Line';
import ValueAxis from '../components/chart/option/axis/ValueAxis';
import XAxis from "../components/chart/option/axis/xAxis";
import YAxis from "../components/chart/option/axis/yAxis";
import DataZoom from "../components/chart/option/zoom/dataZoom";
import { InfrasonicStyle } from './InfrasonicStyle';

export function InfrasonicView({ ip, hip }) {
  const chart = useEChart();
  const [historic] = useGlobal("hip", false);
  const [loading] = useGlobal("iploading", 0);
  const [source, setSource] = useState();

  const actual = ip?.data;
  const historical = hip?.data;
  /**** PERFORMANCE ISSUE: GPU LOCK RISK => BIG DATA UTILS ALGHORITM SPEED UP DATA RENDER AND MEMORY USAGE ***/
  const bigdata = useRef(new BigData());
  const dimension = useRef([]);

  useEffect(() => {
    dimension.current = actual ? actual.legend : [];
    if (actual && !historic) {
      setSource(bigdata.current.setSource(actual.data));
    }
  }, [actual])

  useEffect(() => {
    if (historical) {
      dimension.current = historical ? historical.legend : [];
      setSource(historical.data);
    }
  }, [historical])

  useEffect(() => {
    if (chart.instance) {
      chart.instance.showLoading();
    }
  }, [loading])

  return (
    <EChart loading chart={chart} source={source} dimensions={['time'].concat(dimension.current)} >
      <Legend padding={7} data={dimension.current} />
      <Tooltip trigger="axis" formatter={InfrasonicStyle.TooltipFormat}/>
      <XAxis>
        <TimeAxis axisLabel={{formatter: InfrasonicStyle.dateFormat}}/>
      </XAxis>
      <YAxis>
        <ValueAxis direction="y" min={0} max={value => Math.max(value.max + 10, 180)} scale={true} name={new Date().toUTCString()} nameLocation='end'
          nameTextStyle={{ align: 'left', fontWeight: 'bold', width: 400 }} />
      </YAxis>
      <DataZoom >
        <ZoomSlider />
        {dimension.current.length > 0 &&
          dimension.current.map(() =>
            <ZoomInside />
          )
        }
      </DataZoom>
      {dimension.current.length > 0 &&
        <Series>
          {dimension.current.map((name, i) =>
            i === 0
              ? <Line name={name} encode={{ x: 'Time', y: name }} markLine={InfrasonicStyle.markLine} markArea={InfrasonicStyle.markArea} />
              : <Line name={name} encode={{ x: 'Time', y: name }} />
          )}
        </Series>
      }
      <Features>
        <ExportImage title="SALVA IMMAGINE" excludeComponents={['dataZoom', 'toolbox']} />
        <ExportCSV title="SCARICA DATI" />
      </Features>
    </EChart>
  )
}