import { Button, Table } from "antd";
import { useState } from "react";
import { useControl, useBreakPoint, useGraph } from "@essenza/react";

function WarningController(c) {
    c.skin = WarningView;
    c.command = {

    }
}

export function WarningView() {
    const source = useGraph("system.jsystem");
    const state = source.get("status");
    const breakpoint = useBreakPoint('md');
    const [control] = useControl(WarningController);

    const [col] = useState(() => {
        return defaultCols();
    });

    return (
        <div className="border-round">
            {breakpoint.md.active ? null : <Button style={{ margin: '8px 8px' }} onClick={() => control.navigate("/home")}>Close</Button>}
            <Table className="border-round " expandable={breakpoint.md.active ? null : {
                expandedRowRender: (record) => (
                    <p
                        style={{
                            margin: 0,
                        }}
                    >
                        {record.text}
                    </p>
                ),
                showExpandColumn: false, defaultExpandAllRows: true
            }}
                pagination={{ pageSize: 20, position: ['none', 'none'] }} size="small" columns={col} loading={!state.data?.status} dataSource={state.data?.status} />
        </div>
    )
}

const legend = ["#F8E16C", "#FC8619", "#ED483D"];

function defaultCols() {
    return [
        {
            title: "",
            dataIndex: "value", className: "dark-bg",
            key: "id",
            width: 20,
            render: (text, record) => {
                return (
                    <div>
                        <svg viewBox="0 0 5 5" xmlns="http://www.w3.org/2000/svg">
                            <circle cx="2" cy="2" r="2" fill={legend[record.value]} />
                        </svg>
                    </div>
                );
            },
        },
        {
            title: "State",
            dataIndex: "value",
            className: "dark-bg",
            key: "id",
            width: 44,
            render: (text, record) => {
                return (
                    <div>{'EW' + record.value}</div>
                );
            },
        },
        {
            title: "Time", className: "dark-bg",
            dataIndex: "time",
            key: "id",
            width: 148,
        },
        {
            title: "Details", className: "dark-bg",
            dataIndex: "text",
            key: "id",
            width: 220, ellipsis: true,
            responsive: ['lg'],
        },
        {
            title: "Station", className: "dark-bg",
            dataIndex: "name",
            key: "id",
            width: 46
        },
    ];
}