import { Col, List, Row } from "antd";
import { DrumplotInterval, msInterval } from "../../core/utils";
import { DataGraph } from "@essenza/core";
import { useGraph, useControl, useModel } from "@essenza/react";
import { InfrasonicView } from "../../view/InfrasonicView";
import { RadiativePower } from "../../view/satellite/radiativePower";
import { IoArrowRedoCircleOutline } from 'react-icons/io5'
import { ChannelUtil } from "../../view/station/sensor";
import { ChannelService } from "../../service/ChannelService";
import { SatelliteModel } from "../../models/SatelliteModel";
import { InfrasonicModel } from "../../models/InfrasonicModel";
import { useEffect } from "react";
import { InfrasonicBar } from "../../view/InfrasonicBar";

function HomeMobileVistaController(c) {
  c.skin = HomeMobileVista;
}

export default function HomeMobileVista(props) {
  const [control] = useControl(HomeMobileVistaController);
  const [model] = useModel();
  let source = useGraph("system.jsystem");
  const system = source.data;
  source = useGraph("system.settings");
  const rpower = useGraph(SatelliteModel, "radiativep");
  const ip = useGraph(InfrasonicModel, "ip");
  const hip = useGraph(InfrasonicModel, "hip");
  const settings = source.data;

  // useEffect(() => {
  //   if (model) {
  //     model.read(SatelliteModel, m => m.All(true));
  //   }
  // }, [model]);

  const img = settings?.UrlCamera + new Date().toTimeString();

  const detail = (st) => {

    const sen = st.sensors;

    if (!sen || sen.length === 0)
      return;

    const td = DrumplotInterval(6);
    DataGraph.setSource("system.sensors", { ...st, t: td });
    DataGraph.setSource("station.selected", st);

    const t = new msInterval(100000, 30000);
    const interval = t.next();
    const channel = new ChannelUtil();

    const [name, trace] = channel.getTraceList(sen);

    control.request(ChannelService, s => s.getData(name, interval)).then(result => {
      control.setSource("sensor.jrealtime", channel.formatDataSet(sen, result, trace, name, t));
    });

    control.navigate("mobilechannel");
  }

  return (
    <>
      <img src={img} style={{ width: '100%' }} className="my-auto" alt="Etna Cam screenshot"></img>
      <InfrasonicBar />
      <InfrasonicView ip={ip} hip={hip} />
      <section className="padding-sm" >
        <h3>Station</h3>
        <List
          dataSource={system?.stations}
          renderItem={item => (
            <List.Item>
              <Row onClick={() => detail(item)} className="pointer w100">
                <Col flex="auto" style={{ fontWeight: 'bold' }}>{item.name}</Col>
                <Col flex="none">Canali</Col>
                <Col flex="none"><IoArrowRedoCircleOutline style={{ fontSize: '2em' }} /></Col>
              </Row>
            </List.Item>
          )}
        />
      </section>
      {/* <RadiativePower source={rpower} style={{ paddingLeft: '16px' }} /> */}
    </>
  )
}