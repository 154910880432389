import { Outlet, useLocation, Link } from "react-router-dom";
import { Avatar, Col, Layout, Menu, Row, Spin } from "antd";
import React, { useEffect, useState } from 'react';
import { useApp, useGraph } from "@essenza/react";

import { UserOutlined } from '@ant-design/icons';
import logoLgs from "../assets/img/logo_lgs.png";
import {ReactComponent as MapIcon} from "../assets/img/Mappa.svg";
import {ReactComponent as StazioniIcon} from "../assets/img/Stazioni.svg";
import {ReactComponent as SatelliteIcon} from "../assets/img/Satellite.svg";
import {ReactComponent as BollettinoIcon} from "../assets/img/Bollettino.svg";
import {ReactComponent as SismicaIcon} from "../assets/img/sismica.svg";
import {ReactComponent as OndaIcon} from "../assets/img/Ondametro.svg";
import {ReactComponent as TiltIcon} from "../assets/img/Tiltometro.svg";
import {ReactComponent as CameraIcon} from "../assets/img/Camera.svg";
import {ReactComponent as Ew0} from "../assets/img/ew0.svg";
import {ReactComponent as Ew1} from "../assets/img/ew1.svg";
import {ReactComponent as Ew2} from "../assets/img/ew2.svg";

import { WarningView } from "../view/EWarningView";




const { Header, Content } = Layout;

export function MainLayout({ token }) {

  const system = useGraph("system.status");
  const control = token.current?.control;
  const logged = token.current.logged;

  let state = <Spin tip="Loading Stato Allerta..." className="my-auto" />;
  const data = system.data;

  if(data && data.hasOwnProperty("state"))
  state = [<Ew0  className="my-auto"/>, <Ew1 className="my-auto"/>, <Ew2 className="my-auto"/>][data.state]; 
  useEffect(()=>{  
    if(data && document.getElementById("ew-updated")) {
      if(data.status && data.status.length > 0)
      document.getElementById("ew-updated-d").textContent = data.status[0].duration;
      document.getElementById("ew-updated").textContent = new Date(data.time+'Z').toUTCString().substr(5,17);//new Date(data.time+'Z').toLocaleString();//data.time;
    }
  },[data]);
  return (
    <Layout className="layout">
      <Layout className="layout">
      <Header className="header-top">
        <Row>
          <Col flex="auto">Infrasonic Early Warning of Lava Fountains</Col>
          <Col flex="none" style={{fontWeight:"normal"}}>Powered by GECO</Col>
          <Col flex="none" className="pl-sm font-bold">
            <Avatar style={{ backgroundColor: '#87d068', marginBottom: '4px' }} icon={<UserOutlined onClick={()=>{control.navigate("/profile")}} />} />
          </Col>
        </Row>
      </Header>
      <Header className="header">
        <Row style={{height: '72px'}}>
          <Col span={9} >
            <Menu className="main-menu" defaultSelectedKeys={"map"} mode="horizontal" >
              <Menu.Item  key="map">
                <MapIcon className="block-center"/>
                <Link to="/home">Overview</Link>
              </Menu.Item>
              <Menu.Item key="sta" >
                <StazioniIcon className="block-center"/>
                <Link to="/stazioni">Infrasonic Array</Link>
              </Menu.Item>
              {/*<Menu.Item key="sat" >
                <SatelliteIcon className="block-center"/>
                <Link to="/satellite">Satellite</Link>
              </Menu.Item>
              <Menu.Item key="bol" disabled={!logged} >
                <BollettinoIcon className="block-center"/>
                <Link to="/bollettino">Bollettino</Link>
              </Menu.Item>
              <Menu.Item key="til"  disabled={!logged}>
                <TiltIcon className="block-center" />
                <Link to="/bollettino">Tiltmeters</Link>
              </Menu.Item>
              <Menu.Item key="ela" disabled={!logged} >
                <OndaIcon className="block-center"/>
                <Link to="/bollettino">Elastic Beacons</Link>
              </Menu.Item>
              <Menu.Item key="sei" disabled={!logged} >
                <SismicaIcon className="block-center"/>
                <Link to="/bollettino">Seismic Station</Link>
              </Menu.Item>
              <Menu.Item key="cam" disabled={!logged} >
                <CameraIcon className="block-center"/>
                <Link to="/bollettino">Camera</Link>
              </Menu.Item>*/}
            </Menu>
          </Col>
          <Col span={9} style={{cursor: 'pointer', paddingTop: '4px'}} onClick={()=> control.openPopup(<WarningView/>, "Early Warning")}>{state}</Col>
          <Col span={6} className="d-flex justify-content-end"><img src={logoLgs} height="50" className="my-auto" alt="Logo Laboratorio Geofisica"></img></Col>
        </Row>
      </Header>
        <Content
          className="layout-bg layout-content"
          style={{
            padding: 0,
            minHeight: 280,
          }}
        >
          <Outlet></Outlet>
        </Content>
      </Layout>
    </Layout>
  );
}