import { Button, Row, Tabs } from "antd";
import { useControl, useGlobal, useGraph } from "@essenza/react";
import { ChannelRT } from "../../view/station/sensor";
import { DrumplotFilter } from "../../view/station/DrumplotForm";
import { DrumplotView } from "../../view/station/Drumplot";
import { useEffect, useState } from "react";
import { SystemModel } from "../../models/model";
import { StationModel } from "../../models/StationModel";
import { DrumplotInterval } from "../../core/utils";
import { DrumplotBar } from "../../view/station/DrumplotBar";

function MobileChannelController(c) {
    c.skin = MobileChannelVista;
}

export function MobileChannelVista() {
    const [control] = useControl(MobileChannelController);
    const [fvisibile] = useGlobal("DrumplotFilter", false);
    const [dpdata, setDrumplotData] = useGlobal("DrumplotData", { t: DrumplotInterval(6) });
    const station = useGraph(StationModel, "selected").data;
    const settings = useGraph(SystemModel, "settings");

    const onTabChange = (index) => {
        if (index === "1" && station) { //RESET DRUMPLOT INTERVAL
            setDrumplotData({ ...dpdata, t: DrumplotInterval(6) })
        }
    }

    useEffect(()=>{
        return () => {
            control.setSource("sensor.jrealtime", null);
        }
    }, [control])

    return (
        <Tabs tabBarStyle={{ paddingLeft: '8px' }} onChange={onTabChange} tabBarExtraContent={<Button style={{ marginRight: '8px' }} onClick={() => control.navigate("/home")}>Chiudi</Button>}>
            <Tabs.TabPane tab="Channel" key="0" >
                <ChannelRT />
            </Tabs.TabPane>
            <Tabs.TabPane tab="Drumplot" key="1">
            <DrumplotBar station={station} />
                <Row style={{ backgroundColor: "#000", padding: "8px" }}>
                    {fvisibile ? <DrumplotFilter station={station} /> : null}
                </Row>
                <DrumplotView settings={settings.data} station={station} data={dpdata} />
            </Tabs.TabPane>
        </Tabs>
    )
}