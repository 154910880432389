import { Button, Col, DatePicker, Select, Row } from 'antd';
import { DrumplotInterval } from '../core/utils';
import { useControl, useModel } from "@essenza/react";

import moment from 'moment';
import { InfrasonicModel } from '../models/InfrasonicModel';
import { useEffect, useState } from 'react';

const { Option } = Select;

function IpBarControl(c) {
    c.skin = InfrasonicBar;
    c.count = 1;
    c.command = {
        DATE_CHANGE: (date, { model, data }) => {
            const d = date.toDate();
            d.setHours(23, 59, 59);
            let p = DrumplotInterval(24, 0, d);
            model.setGlobal("hip", true);
            model.setGlobal("iploading", c.count++);
            
            model.read(InfrasonicModel, m => m.getHistoricData(p));
            model.setDate(date);
            model.setOption("1");
        },

        TIME_CHANGE: (value, { model }) => {
            let p = DrumplotInterval(24 * value);
            p.avg = value >= 29;
            const d = moment(new Date(), 'DD/MM/YYYY/');
            model.setGlobal("hip", false); //Papabile for useGlobalRef => not rerender needed
            model.setGlobal("iploading", c.count++);
            model.read(InfrasonicModel, m => m.getData(p));
            model.setDate(d);
            model.setOption(value);
        }
    }
}

export function InfrasonicBar() {
    const [control] = useControl(IpBarControl);
    const [model] = useModel();
    const [date, setDate] = useState(moment(new Date(), 'DD/MM/YYYY/'));
    const [option, setOption] = useState("1");

    useEffect(() => {
        model.setDate = setDate;
        model.setOption = setOption;
    }, [model]);

    return (
        <Row style={{ backgroundColor: '#E9EDF5' }} align="middle" justify="center">
            <Col span={24} lg={10} ><h4 style={{ marginLeft: '8px', textAlign: 'center' }}>Infrasonic EW parameter</h4></Col>
            <Col flex="none">
                <Button type='link' onClick={() => control.execute("TIME_CHANGE", "1", null, model)}>Today</Button>
            </Col>
            <Col flex="none">
                <Button onClick={() => control.execute("DATE_CHANGE", date.add(-1, 'd').clone(), null, model)}>{'<'}</Button>
            </Col>
            <Col flex="none">
                <DatePicker onChange={(d) => control.execute("DATE_CHANGE", d, null, model)} value={date} defaultValue={date} />
            </Col>
            <Col flex="none">
                <Button onClick={() => control.execute("DATE_CHANGE", date.add(1, 'd').clone(), null, model)}>{'>'}</Button>
            </Col>
            <Col flex="none">
                <Select value={option} defaultValue="1" style={{ width: 160 }} onChange={(v) => control.execute("TIME_CHANGE", v, null, model)} bordered={false}>
                    <Option value="1">Last 24h</Option>
                    <Option value="7">Last Week</Option>
                    <Option value="30">Last Month</Option>
                    <Option value="360">Last Year</Option>
                </Select>
            </Col>
        </Row>
    )
}