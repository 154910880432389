import { Button, Col, DatePicker, Row, Select } from "antd";
import { useEffect, useRef, useState } from "react";
import moment from 'moment';

import { adjustParams } from "../../core/utils";
import { useControl, useModel } from "@essenza/react";
import { StationModel } from "../../models/StationModel";

const { Option } = Select;

function EWBarController(c) {
    c.skin = EWBar;
    c.command = {

        DATE_CHANGE: (date, { model, data }) => {
            const station = data;
            const interval = model.getGlobal("station.timeinterval");//.source;
            let d = date.toDate();
            d.setHours(23);
            d.setMinutes(59);
            interval.te = d;
            model.read(StationModel, m => m.GetData(adjustParams(station, interval)));
            model.setDate(date);
        },

        TIME_CHANGE: (value, { model, data }) => {
            const station = data;
            const interval = model.getGlobal("station.timeinterval");
            interval.offset = value;
            model.read(StationModel, m => m.GetData(adjustParams(station, interval)));
        }
    }
}

export function EWBar({ station }) {
    const [control] = useControl(EWBarController);

    const [model] = useModel();
    const [date, setDate] = useState(moment(new Date(), 'DD/MM/YYYY/'))

    const ref = useRef();

    useEffect(() => {
        model.setDate = setDate;
    }, [model])

    return (
        <Row>
            <Col flex="none">
                <Button onClick={() => control.execute("DATE_CHANGE", date.add(-1, 'd').clone(), station, model)}>{'<'}</Button>
            </Col>
            <Col flex="none">
                <DatePicker ref={ref} onChange={(date) => control.execute("DATE_CHANGE", date, station, model)} value={date} defaultValue={date} />
            </Col>
            <Col flex="none">
                <Button onClick={() => control.execute("DATE_CHANGE", date.add(+1, 'd').clone(), station, model)}>{'>'}</Button>
            </Col>
            <Col flex="none">
                <Select defaultValue="24" style={{ width: 160 }} onChange={(v) => control.execute("TIME_CHANGE", v, station, model)} bordered={false}>
                    <Option value="6">Last 6H</Option>
                    <Option value="12">Last 12H</Option>
                    <Option value="24">Last 24H</Option>
                </Select>
            </Col>
        </Row>
    )
}