import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';
import { BrowserRouter } from 'react-router-dom';
import {Helmet} from "react-helmet";
import logoGeCo from "./assets/img/geco_icon.ico";


/* CSS Variables => vedi compatibilità

import { ConfigProvider } from 'antd';
ConfigProvider.config({
  theme: {
    primaryColor: '#25b864',
  },
});
<ConfigProvider prefixCls="custom">
    <MyApp />
  </ConfigProvider>
*/
//{document.baseURI.substring(document.baseURI.indexOf(window.location.origin) + window.location.origin.length, document.baseURI.lastIndexOf('/'))} basename="/app"
const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
    <BrowserRouter>
        <Helmet>
    <title>Etna</title>
    <link rel="icon"  href={logoGeCo} sizes="16x16" type="image/x-icon" data-react-helmet="true" />
    <meta name="description" content="Etna monitor" />
   
    </Helmet>
      <App />
    </BrowserRouter>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
