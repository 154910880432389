import { Form, Input, Button } from 'antd';
import { useControl } from "@essenza/react";
import { IntervalWithOffset } from '../../core/utils';

function DrumplotFilterController(c) {
    c.skin = DrumplotFilter;
    c.command = {
        PROCESS: (values, {model}) => {
            let data = model.getGlobal("DrumplotData");
            let changed = false;

            if (values.te) {
                changed = true;
                data.t.te = new Date(values.te).toISOString().substring(0, 19);
            }
            if (values.ts) {
                changed = true;
                data.t.ts = new Date(values.ts).toISOString().substring(0, 19);
            }
            if (values.fmin) {
                data.fmin = values.fmin;
                changed = true;
            }
            if (values.fmax) {
                data.fmax = values.fmax;
                changed = true;
            }
            if (values.yr) {
                data.yr = values.yr;
                changed = true;
            }

            if (changed) {
                model.setGlobal("DrumplotData", {...data});
            }
        },
    }
}

export function DrumplotFilter({ station }) {
    const [form] = Form.useForm();
    const [control] = useControl(DrumplotFilterController);
    const [sdate, edate] = IntervalWithOffset(6);

    return (
        <Form labelWrap={true} className="dp-filter" style={{ backgroundColor: 'black', color: "white", padding: "12px" }} name="horizontal_login" layout="inline" onFinish={(v)=>control.execute("PROCESS",v)}>
            <Form.Item style={{ color: "white", marginTop: '8px' }} label="T.start" name="ts" >
                <Input style={{ backgroundColor: "black", color: "white" }} defaultValue={sdate} type="datetime-local" placeholder="Start" />
            </Form.Item>
            <Form.Item label="T.end" name="te" style={{ marginTop: '8px' }} >
                <Input style={{ backgroundColor: "black", color: "white" }} defaultValue={edate} type="datetime-local" placeholder="End" />
            </Form.Item>
            <Form.Item label="Fmin (Hz)" name="fmin" style={{ marginTop: '8px' }}>
                <Input style={{ backgroundColor: "black", color: "white" }} defaultValue="0.5" type="number" placeholder="Fmin" />
            </Form.Item>
            <Form.Item label="Fmax (Hz)" name="fmax" style={{ marginTop: '8px' }}>
                <Input style={{ backgroundColor: "black", color: "white" }} defaultValue="10" type="number" placeholder="Fmax" />
            </Form.Item>
            <Form.Item label="Yr (Pa)" name="yr" style={{ marginTop: '8px' }}>
                <Input style={{ backgroundColor: "black", color: "white" }} defaultValue="1" type="number" placeholder="Yr" />
            </Form.Item>
            <Form.Item shouldUpdate style={{ marginTop: '8px' }}>
                {() => (
                    <Button className='button-width'
                        type="primary"
                        htmlType="submit"
                        disabled={
                            !form.isFieldsTouched(true) ||
                            !!form.getFieldsError().filter(({ errors }) => errors.length).length
                        }
                    >
                        Process
                    </Button>
                )}
            </Form.Item>
        </Form>
    )
}