import { DataModel } from "@essenza/core";

export function SatelliteModel() {
  DataModel.call(this, "satellite");

  this.All = (permanent) => {
    this.ExecuteApi(`jsat: system{
            radiativep:  satellite { values },
            images: satellite {time, impath, values},
          }`, null, { many: true }, permanent);
  };

  this.Mirova = () => {
    this.ExecuteApi("mirova: satellite{time, path}");
  };
}