import useChart from "../../chartContext";
import Feature from "./feature";

const ExportCSV = ({icon, ...rest}) => {
    const chart = useChart();

    rest.icon = icon || 'path://M18 21H6a3 3 0 0 1-3-3V6a3 3 0 0 1 3-3h12a3 3 0 0 1 3 3v12a3 3 0 0 1-3 3zM6 5a1 1 0 0 0-1 1v12a1 1 0 0 0 1 1h12a1 1 0 0 0 1-1V6a1 1 0 0 0-1-1z';
    rest.onclick = function () {
        const option = chart.instance?.getOption();
        const legend = option.legend[0].data;
        const data = option.dataset[0]?.source;
        debugger
        if(data){
            let csvContent = "data:text/csv;charset=utf-8,Time," + legend.join(",") + "\n";
            csvContent +=  data.map(e => {
               let v = new Date(e[0]).toISOString()
               for (let k = 1; k < e.length; k++) {
                v += "," + e[k];
               }
               return v;
            }).join("\n");
            var encodedUri = encodeURI(csvContent);
            window.open(encodedUri);
        }
        
        /* 
            var encodedUri = encodeURI(csvContent);
            var link = document.createElement("a");
            link.setAttribute("href", encodedUri);
            link.setAttribute("download", "my_data.csv");
            document.body.appendChild(link); 
            link.click();
        */
    }

	return <Feature name="myFeature" {...rest} />;
};

export default ExportCSV;