import { useRef} from "react";
import { fromLonLat } from "ol/proj"
import { useControl } from "@essenza/react";
import {  msInterval } from "../core/utils";
import { ChannelRT, ChannelUtil } from "./station/sensor";
import Map from "../components/map/map";
import OSMLayer from "../components/map/layer/OSMLayer";
import ContourLayer from "../components/map/layer/ContourLayer";
import StationLayer from "../lib/map/layer/StationLayer";
import SensorLayer from "../lib/map/layer/SensorLayer";
import { useInfrasonicCurve, useStation } from "../lib/hook/maphook";
import FullScreenControl from "../components/map/control/FullscreenControl";
import { LayerControl } from "../lib/map/control/LayerControl";
import { MapStyle } from "./MapStyle";
import { ElementSwitch } from "../lib/map/control/SwitchControl";
import ModisLayer from "../lib/map/layer/ModisLayer";
import InfrasonicLayer from "../lib/map/layer/InfrasonicLayer";
import Event from "../components/map/event/event";
import { ChannelService } from "../service/ChannelService";

function mapViewControl(c) {
  c.skin = MapView;
  c.command = {
    ON_CHANNEL: (map, { data }) => {
      const t = new msInterval(100000, 30000);
      const interval = t.next();
      const channel = new ChannelUtil();
      const onclose = ()=>{
          c.setSource("sensor.jrealtime", null);
      }
      var feature = map.forEachFeatureAtPixel(data.evt.pixel,
        function (feature) {
          return feature;
        });

      if (feature.get('type') === 'STA') {
        const station = feature.get('item');
        const sensors = station.sensors;
        if (!sensors || sensors.length === 0)
          return;

        const [name, trace] = channel.getTraceList(sensors);

        c.request(ChannelService, s => s.getData(name, interval)).then(result => {
          c.setSource("sensor.jrealtime", channel.formatDataSet(sensors, result, trace, name, t));
        });

        c.openPopup(<ChannelRT  />, "STAZIONE " + station.name, null, {onclose: onclose}); //window.innerWidth
      }
      else if (feature.get('type') === 'SEN') {
        const station = feature.get('station');
        const sensor = feature.get('item');

        c.request(ChannelService, s => s.getData(sensor.trace_id, interval)).then(result => {
          c.setSource("sensor.jrealtime", channel.formatData(sensor, result, t));
        });

        c.openPopup(<ChannelRT />, "STAZIONE " + station.name + " SENSORE " + sensor.name, null, {onclose: onclose});
      }
    }
  }
}

const ETNA_CENTER = fromLonLat([15.0270243852738, 37.7244216495832]);

export function MapView({ ip, source, mirova, settings }) {
  const [control] = useControl(mapViewControl);

  const system = source.data;

  const [station, sensor] = useStation(system?.stations);
  const [curve] = useInfrasonicCurve(ip.data);

  const ref = useRef();

  return (
    <Map ref={ref} className="map" center={ETNA_CENTER} zoom={12}>
      <Map.Events>
        <Event on="click" callback={(e, map) => control.execute("ON_CHANNEL", map, { evt: e })} />
      </Map.Events>
      <Map.Layers>
        <OSMLayer name="osm" />
        <ContourLayer name="contour" />
        <ModisLayer url={settings?.UrlModis + mirova?.path} />
        <StationLayer source={station} />
        <SensorLayer source={sensor} />
        <InfrasonicLayer source={curve} />
      </Map.Layers>
      <Map.Controls>
        <FullScreenControl />
        <LayerControl layers={MapStyle.layers} />
        <ElementSwitch layer="stations" childLayer="sensors" visibility={{ ETN: false }} elements={system?.stations} />
      </Map.Controls>
    </Map>
  )
}
