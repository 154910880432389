import { useMemo } from "react";
import Point from 'ol/geom/Point';
import Feature from 'ol/Feature';
import { fromLonLat } from "ol/proj"
import VectorSource from 'ol/source/Vector';
import { centroid } from "../../core/utils";
import WKT from 'ol/format/WKT';

export function useStation(stations) {
  const [station, sensor, center] = useMemo(() => {
    const f = [];
    const sf = [];
    const centers = []
    if (stations) {
      if (!Array.isArray(stations))
        stations = [stations];
      const len = stations.length;
      for (let k = 0; k < len; k++) {
        const s = stations[k];

        const c = s.geom.type === 'Polygon' ? centroid(s.geom.coordinates[0]) : s.geom.coordinates;

        f.push(new Feature({ type: 'STA', index: k, item: s, name: s.name, geometry: new Point(fromLonLat(c)) }));

        const d = c.slice(0);
        d[1] = d[1] + 0.035;
        centers.push(fromLonLat(d));
        const sensors = s.sensors;

        if (sensors) {
          let p;
          for (let i = 0; i < sensors.length; i++) {
            const sen = sensors[i];
            if (sen) {
              p = sen.geom.coordinates;
              sf.push(new Feature({ type: 'SEN', sindex: k, index: i, item: sen, station: s, sname: s.name, name: s.name + ': ' + sen.name, status: sen.status, geometry: new Point(fromLonLat(p)) }));
            }
          }
        }
      }
    }
    return [new VectorSource({ features: f, }), new VectorSource({ features: sf, }), centers];
  }, [stations]);

  return [station, sensor, center];
}

export function useInfrasonicCurve(data) {
  return useMemo(() => {
    const ipfeatures = [];
    const values = [];
    if (data && data.length > 0) {
      const format = new WKT();
      let f;
      for (let k = 0; k < data.length; k++) {
        f = format.readFeature(data[k].geom, {
          dataProjection: 'EPSG:4326',
          featureProjection: 'EPSG:3857',
          //ivalue: data[k].value,
        });
        f.set("ivalue", data[k].value);
        ipfeatures.push(f);
        values.push(data[k].value);
      }
    }
    return [new VectorSource({ features: ipfeatures, })];
  }, [data]);
}