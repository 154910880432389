import './App.scss';
import React, { useEffect, useMemo, useRef } from 'react';
import { Route, Routes, useNavigate, useSearchParams } from 'react-router-dom';

import { version, Test, DataGraph, ExecuteApi } from '@essenza/core';
import { AppRoot, useBreakPoint } from '@essenza/react';

import { AppSchema } from './schema';
import { MainLayout } from './layout/MainLayout';
import { ProfileVista } from './vista/profile/ProfileVista';

import HomeVista from './vista/HomeVista';

import { MobileLayout } from './layout/MobileLayout';
import HomeMobileVista from './vista/mobile/HomemobileVista';
import { MobileChannelVista } from './vista/mobile/MobileChannelVista';
import { MobileStationVista } from './vista/mobile/MobileStationVista';
import { MobileMenuVista } from './vista/mobile/MobileMenuVista';

import StationVista from './vista/StationVista';
import SatelliteVista from './vista/SatelliteVista';
import BollettinoVista from './vista/BollettinoVista';
import { WarningView } from './view/EWarningView';
import { NextAtSecond } from './core/utils';
import { LoginVista } from './vista/profile/LoginVista';
import { SigninVista } from './vista/profile/SigninVista';
import { RecoverVista } from './vista/profile/RecoverVista';
import { Welcome } from './vista/Welcome';
import { CheckEmail } from './vista/profile/Signin';
import { ChannelService } from './service/ChannelService';


import {Helmet} from "react-helmet";
import logoGeCo from "./assets/img/geco_icon.ico";

let initialized = false;

function updateData() {
  const graph = `jsystem: system{
    ip:  infrasonic { values },
    status: system {state, time},
    stations: system { id, name }
  }`;
  ExecuteApi(graph, null, true, true);
  setTimeout(updateData, NextAtSecond(5));
}

/**
 * 
 * @param {VistaApp} app 
 */
function initApp(app) {

  console.log("INIT APP", app);

  if (!initialized) {
    /** ATTENZIONE NON COMPATIBILE CON TUTTI I BROWSER => GESTIRE **/
    /*if (window.screen?.orientation?.lock)
      window.screen.orientation.lock("portrait").then(r => r, e => e);*/
    return fetch(window.location.origin + "/appsettings.json", { headers: { 'Content-Type': 'application/json', 'Accept': 'application/json' } }).then(r => r.json()).then(r => {
      console.log("CONFIG-DATA", r);
      ChannelService.url = r.UrlChannel;
      DataGraph.setSource("system.settings", r);
      initialized = true;
    });
  }

  return null;
}

var attempt = -1;
const maxAttempt = 5;
/**
 * 
 * @param {VistaApp} app 
 */
function onlogin(app) {
  console.log("ON LOGIN", app);
  if (!initialized) {
    attempt++;
    if(attempt < maxAttempt)
      initApp(app).then(() => onlogin(app), () => onlogin(app));
    else
      app.control.openPopup(<div>Error on lunch application, plese refresh page.</div>, "ERROR");
  }
  else {
    updateData();
    app.navigate("/home");
  }
}

function AppController(c) {
  c.skin = App;
  c.command = null;
}

//http://geco.iconsultant.it/  https://localhost:44380/

function App() {
  console.log("APP-PROVIDER-BP", version, Test);
  const nav = useNavigate();
  const [qp] = useSearchParams();
  const token = useRef();
  const home = useMemo(() => <HomeMobileVista />, []);
  const breakpoint = useBreakPoint('md');

  return (
    
    <AppRoot token={token} init={initApp} control={AppController} navigator={nav} qp={qp} onlogin={onlogin} baseurl="http://65.21.206.107/etna" schema={AppSchema}>

      <Routes>
        {breakpoint.md.active
          ? <Route path="/" element={<MainLayout token={token} />}>
            <Route index element={<Welcome token={token} />} />
            <Route path="home" element={<HomeVista />} />
            <Route path="stazioni" element={<StationVista />} />
            <Route path="satellite" element={<SatelliteVista />} />
            <Route path="bollettino" element={<BollettinoVista />} />
          </Route>
          :
          <Route path="/" element={<MobileLayout token={token} />}>
            <Route index element={<Welcome token={token} />} />
            <Route path="home" element={home} />
            <Route path="mobilemenu" element={<MobileMenuVista />} />
            <Route path="mobilestation" element={<MobileStationVista />} />
            <Route path="mobilew" element={<WarningView />} />
          </Route>
        }
        <Route path="mobilechannel" element={<MobileChannelVista />} />
        <Route path="login" element={<LoginVista />} />
        <Route path="profile" element={<ProfileVista />} />
        <Route path="signin" element={<SigninVista />} />
        <Route path="onsignin" element={<CheckEmail />} />
        <Route path="loginrec" element={<RecoverVista />} />
      </Routes>

      <Helmet>
    <title>Etna</title>
    <link rel="icon"  href={logoGeCo} sizes="16x16" type="image/x-icon" data-react-helmet="true" />
    <meta name="description" content="Etna monitoring" />
   
    </Helmet>
   
    </AppRoot>
    
  );
}

export default App;
