import { List } from "antd";
import { useGraph, useControl } from "@essenza/react";

function MobileMenuController(c) {
    c.skin = MobileMenuVista;
}

export function MobileMenuVista() {
    const [control] = useControl(MobileMenuController);
    const source = useGraph("system.jsystem");
    const system = source.data;
    return (
        <div className="h-mob" style={{ backgroundColor: 'black' }}>
            <section className="padding-sm"  >
                <h2 onClick={() => control.navigate('/home')} className="pointer" style={{ textAlign: 'center', color: 'white', fontWeight: 'bold', paddingTop: '36px' }}>OVERVIEW</h2>
                <h6 style={{ textAlign: 'center', color: 'gray', width: '100%', paddingTop: '36px' }}>Infrasonic detection statistics</h6>
                <List
                    dataSource={system?.stations}
                    renderItem={item => (
                        <List.Item >
                            <h2 className="pointer" onClick={() => control.navigate('/mobilestation', { state: item })} style={{ textAlign: 'center', color: 'white', fontWeight: 'bold', width: '100%' }} >{item.name.toUpperCase()}</h2>
                        </List.Item>
                    )}
                />
            </section>
        </div>
    )
}