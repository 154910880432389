import ImageLayer from "../../../components/map/layer/ImageLayer";
import Static from 'ol/source/ImageStatic';
import { transformExtent } from "ol/proj";
import { useMemo, useRef } from "react";

const ModisLayer = ({ name = 'mirova', opacity = 0.8, visible = false, coordinate = [14.7194241352622, 37.9589937690583, 15.2868561695722, 37.5083146151764], url, ...rest }) => {
    const c = useRef(coordinate)
    const source = useMemo(() => new Static({
        attributions: '© Mirova',
        url: url, 
        projection: 'EPSG:3857', 
        imageExtent: transformExtent(c.current, 'EPSG:4326', 'EPSG:3857'),
    }), [url]);

    return <ImageLayer name={name} attributions="© Mirova" opacity={opacity} visible={visible} source={source} {...rest} />;
};

export default ModisLayer;