import { Style, Icon, Stroke, Text } from 'ol/style';
import Fill from "ol/style/Fill";
import icon from "../../../assets/img/station.svg";

const StationStyle = (feature) => {
    return new Style({
        image: new Icon({
            anchor: [0.5, 1],
            src: icon,
            scale: 0.7,
        }),
        text: new Text({
            font: 'bold 14px Arial',
            text: feature.get('name'),
            fill: new Fill({ color: 'black' }),
            stroke: new Stroke({ color: 'black', width: 0.5 }),
            offsetY: -22, offsetX: 16,
        })
    })
}

export default StationStyle;