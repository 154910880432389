import { useRef, } from "react";
import { fromLonLat } from "ol/proj"
import Map from "../../components/map/map";
import OSMLayer from "../../components/map/layer/OSMLayer";
import ContourLayer from "../../components/map/layer/ContourLayer";
import StationLayer from "../../lib/map/layer/StationLayer";
import SensorLayer from "../../lib/map/layer/SensorLayer";
import InfrasonicLayer from "../../lib/map/layer/InfrasonicLayer";
import FullScreenControl from "../../components/map/control/FullscreenControl";
import { useInfrasonicCurve, useStation } from "../../lib/hook/maphook";

const ETNA_CENTER = fromLonLat([15.0270243852738, 37.7244216495832]);

export function InfrasonicMap({ ip, station} ) {
  const [current, sensor, center] = useStation(station);
  const [curve] = useInfrasonicCurve(ip.data);

  const ref = useRef();

  return (
    <Map ref={ref} className="map" center={center ? center[0] : ETNA_CENTER } zoom={12}>
      <Map.Layers>
        <OSMLayer name="osm" />
        <ContourLayer name="contour" />
        <StationLayer source={current} />
        <SensorLayer source={sensor} />
        <InfrasonicLayer source={curve} />
      </Map.Layers>
      <Map.Controls>
        <FullScreenControl />
      </Map.Controls>
    </Map>
  )
}