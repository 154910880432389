import { useEffect, useRef } from "react";
import { useLocation } from "react-router-dom";
import { adjustParams, NextAtSecond } from "../../core/utils";
import { DataGraph, ExecuteApi } from "@essenza/core";
import { useGraph } from "@essenza/react";
import { StatusBar } from "../../view/station/status";
import { StationModel } from "../../models/StationModel";

export function MobileStationVista(){
    const stationdata = useGraph(StationModel, "jstationdata");
    const source = useGraph("station.timeinterval", null, {offset: 24, te: new Date()})
    const timeInterval = source.data;
    const station = useLocation().state;
    const initialized = useRef(false);

    function updateStationData(){
        timeInterval.te = new Date();
        ExecuteApi("jstationdata: station {signal, processed, trasmitted, infrasonic, values }", adjustParams(station, timeInterval));
        setTimeout(updateStationData, NextAtSecond(10));
    }

    useEffect(() => {
        if (!initialized.current) {
                DataGraph.setSource("station.selected", station);
                updateStationData();
                initialized.current = true;
            }
        }
    , [station]);

    return(
        <div className="h-mob" style={{backgroundColor: 'black'}}>
            <h2 style={{ textAlign: 'center', color: 'white', fontWeight: 'bold', paddingTop: '36px' }}>{station?.name.toUpperCase()}</h2>
            <StatusBar source={stationdata} />
        </div>
    )
}