import { Style, Stroke } from 'ol/style';

const ColorMap = {
    '30': new Style({
        stroke: new Stroke({
            width: 2,
            color: 'rgb(0, 0, 255)'
        })
    }),
    '45': new Style({
        stroke: new Stroke({
            width: 2,
            color: '#58D9F9'
        })
    }),
    '60': new Style({
        stroke: new Stroke({
            width: 2,
            color: '#F8E16C'
        })
    }),
    '75': new Style({
        stroke: new Stroke({
            width: 2,
            color: '#FC8619'
        })
    }),
    '90': new Style({
        stroke: new Stroke({
            width: 2,
            color: 'rgb(255, 0, 0)'
        })
    }),
    '0': new Style({
        stroke: new Stroke({
            width: 4,
            color: '#333',
            lineDash: [8]
        })
    }),
};

export const InfrasonicStyle =
{
    format: function (feature) {
        return ColorMap[feature.get('ivalue')];
    },
    colorMap: ColorMap,
}
