import { List } from "antd";
import { DataGraph } from "@essenza/core";
import { useGraph } from "@essenza/react";

export function SatImages({source}) {
    const settings = useGraph("system.settings").data;
    const data = source.data;
    return (
        <div className="scroll"  style={{height: 'calc(100vh - 170px)', marginLeft: '8px', overflowY: 'auto',  overflowX: 'hidden'}}>
        {data?<List  loading={!data}
            grid={{
                gutter: 16,
                xs: 1,
                sm: 2,
            }}
            dataSource={data}
            renderItem={item => (
                <List.Item>
                    <div className="text-center">
                        <img onMouseEnter={(e)=>{DataGraph.setSource("satellite.image",item );}} onClick={(e)=>{DataGraph.setSource("satellite.image",item);}} alt="" src={settings.UrlModis + item.impath} />
                        <h6>{new Date(item.time + 'Z').toLocaleString()}</h6>        
                    </div>
                </List.Item>
            )}
        />:<div style={{width: '100%'}}>No images to show.</div>}
        </div>
    )
}