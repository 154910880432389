import { Col, Row, Tabs } from "antd";
import { useEffect, useState } from "react";
import { SatImages } from "../view/satellite/image";
import { MapSatelliteView } from "../view/satellite/map";
import { RadiativePower } from "../view/satellite/radiativePower";
import { Vista, useVista, useModel, useGraph } from "@essenza/react";
import { SatelliteModel } from "../models/SatelliteModel";

const { TabPane } = Tabs;
function SatelliteVistaController(c) {
    c.skin = SatelliteVista;
}

export default function SatelliteVista(props) {
    const [ctx] = useVista(SatelliteVistaController);
    const [model] = useModel();
    const images = useGraph(SatelliteModel, "images");
    const rpower = useGraph(SatelliteModel, "radiativep");

    const [viewkey, setViewKey] = useState(600);
    const mapRef = {};

    //BUG FIX (TODO): Chart Resize on Sleep state => force to update on tab activation!!!
    useEffect(() => {
        if (model) {
            model.read(SatelliteModel, m => m.All());
        }
    }, [model]);

    useEffect(() => {
        mapRef.current.updateSize();
    }, [viewkey]);

    return (
        <Vista context={ctx} >
            <Row className="station-vista" wrap={false} style={{ height: '100%', backgroundColor: '#E9EDF5' }}>
                <Col flex={viewkey + "px"} style={{ height: 'calc(100vh - 108px)' }}>
                    <h4 className=" py-0 my-0 bg-dark text-white text-center w-100">MIROVA</h4>
                    <MapSatelliteView refs={mapRef} style={{ height: 'calc(100vh - 138px)' }} />
                </Col>
                <Col flex="auto" style={{ height: 'calc(100vh - 108px)' }} >
                    <Tabs onChange={(key) => { setViewKey(1200 / key) }} defaultActiveKey="2" type="line" tabPosition="top" tabBarGutter="8" tabBarStyle={{ backgroundColor: '#E9EDF5', marginLeft: '16px' }} style={{ height: '100%' }}>
                        <TabPane tab="Latest Images" key="1">
                            <SatImages source={images} />
                        </TabPane>
                        <TabPane tab="Radiative Power" key="2" style={{ height: '100%' }} >
                            <RadiativePower source={rpower} />
                        </TabPane>
                    </Tabs>
                </Col>
            </Row>
        </Vista>
    )
}