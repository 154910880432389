export function sleep(timeout) {
    return new Promise((resolve) => setTimeout(resolve, timeout));
  }

export function DrumplotInterval(hours, offset, date){
    offset = offset || 2;
    const result = {};
    const dt = date || new Date();
    result.local = dt.toLocaleString().substring(11);
    //dt.setHours( dt.getHours() + offset );
    result.te = dt.toISOString().substring(0, 19);
    dt.setHours( dt.getHours() - hours);// + offset );
    result.ts = dt.toISOString().substring(0, 19);
    //dt.setHours( dt.getHours() - offset );
    result.local = dt.toLocaleString() + " - " + result.local;
    return result;
}

export function IntervalWithOffset(offset, date){
    date = date || new Date();
    date.setMinutes(date.getMinutes() - date.getTimezoneOffset());
    const edate = date.toISOString().slice(0, 16);
    date.setHours(-offset);
    const sdate = date.toISOString().slice(0, 16);
    return [sdate, edate];
}



export function msInterval(ms, offset){
  this.dt = new Date();
  this.dtms = this.dt.getTime();
  this.dt.setTime(this.dt.getTime() - offset - ms);
  this.ms = ms;
  this.offset = offset;
  this.next = function(){
    const result = {}
    result.local = this.dt.toLocaleString().substring(11);
    result.ts = this.dt.toISOString().substring(0, 19);
    this.dt.setTime( this.dt.getTime() + this.ms);
    result.te = this.dt.toISOString().substring(0, 19);
    result.local = this.dt.toLocaleString() + " - " + result.local;
    this.dtms = this.dt.getTime();
    return result;
  }

  this.restore = function(){
    this.dt.setTime( this.dt.getTime() - this.ms);
  }
}

export function NextAtSecond(second){
  const s = new Date().getSeconds();
  if(s<second)
    return (second - s) * 1000;
  else
    return ((60-s) + second) * 1000;
}

export const isToday = (date) => {
  const today = new Date()
  return date.getDate() === today.getDate() &&
  date.getMonth() === today.getMonth() &&
  date.getFullYear() === today.getFullYear()
}

export const isoDate = (date) =>{
  return date.toISOString().substring(0, 19)
}

export const dateAddDays = (date, days) => {
  date.setDate(date.getDate() + days);
}

export const dateAddHours = (date, hours) => {
  const d = new Date(date.getTime());
  d.setHours(date.getHours() + hours);
  return d;
}

export function adjustParams(station, interval){
  return {station: station.name, te: isoDate(interval.te) , ts: isoDate(dateAddHours(interval.te, -interval.offset)) };
}

export function centroid(coordinates) {
  let pts = [];
  for (let k = 0; k < coordinates.length-1; k++) {
    pts.push({x: coordinates[k][0], y: coordinates[k][1]});
  }
  var first = pts[0], last = pts[pts.length-1];
  if (first.x !== last.x || first.y !== last.y) pts.push(first);
  var twicearea=0,
  x=0, y=0,
  nPts = pts.length,
  p1, p2, f;
  for ( var i=0, j=nPts-1 ; i<nPts ; j=i++ ) {
     p1 = pts[i]; p2 = pts[j];
     f = (p1.y - first.y) * (p2.x - first.x) - (p2.y - first.y) * (p1.x - first.x);
     twicearea += f;
     x += (p1.x + p2.x - 2 * first.x) * f;
     y += (p1.y + p2.y - 2 * first.y) * f;
  }
  f = twicearea * 3;
  return [x/f + first.x, y/f + first.y ];
}

export function isInside(point, vs) {
  var x = point[0], y = point[1];
  
  var inside = false;
  for (var i = 0, j = vs.length - 1; i < vs.length; j = i++) {
      var xi = vs[i][0], yi = vs[i][1];
      var xj = vs[j][0], yj = vs[j][1];
      
      var intersect = ((yi > y) !== (yj > y))
          && (x < (xj - xi) * (y - yi) / (yj - yi) + xi);
      if (intersect) inside = !inside;
  }
  
  return inside;
};