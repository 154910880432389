export const InfrasonicStyle = {
  dateFormat: (value, index) => new Date(value).getUTCHours() + ':00',

  TooltipFormat: (params) => {
    //console.log("PARAMS", params);
    return `
          <b>${new Date(params[0].axisValue).toUTCString()}</b> <br />
         ${params[0].marker} ${params[0].seriesName}: ${params[0].value[1]}<br />
          ${params[1].marker} ${params[1].seriesName}: ${params[0].value[2]}<br />
          ${params[2].marker} ${params[2].seriesName}: ${params[0].value[3]}
          `;
  },
  
  markLine: {
        silent: true,
        lineStyle: {
          color: '#333'
        },
        data: [
          {
            yAxis: 60
          },
          {
            yAxis: 120
          },
        ]
      },
      markArea: {
        silent: true,
        label: { position: 'inside', color: '#000' },

        data: [
          [{
            name: 'EW0',
            yAxis: 0,
            itemStyle: { color: '#F8E16C', opacity: 0.4 },
          },
          {
            yAxis: 60
          }],
          [{
            name: 'EW1',
            yAxis: 60,
            itemStyle: { color: '#FC8619', opacity: 0.4 },
          },
          {
            yAxis: 120
          }]
          ,
          [{
            name: 'EW2',
            yAxis: 120,
            itemStyle: { color: '#ED483D', opacity: 0.4 },
          },
          {
            yAxis: 1200
          }]
        ],
        animation: false,
      }
}