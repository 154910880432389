import { Col, Row } from "antd";
import { CameraView } from "../view/CameraView";
import { InfrasonicView } from "../view/InfrasonicView";
import { MapView } from "../view/MapView";
import { Vista, useVista, useModel, useGraph } from "@essenza/react";
import { InfrasonicModel } from "../models/InfrasonicModel";
import { SatelliteModel } from "../models/SatelliteModel";
import { SystemModel } from "../models/model";
import { useEffect } from "react";
import { InfrasonicBar } from "../view/InfrasonicBar";

function HomeVistaController(c) {
    c.skin = HomeVista;
}

export default function HomeVista() {
    const [ctx] = useVista(HomeVistaController);
    const [model] = useModel();

    const system = useGraph(SystemModel, "jsystem");
    const mapip = useGraph(InfrasonicModel, "jicurveat");
    const ip = useGraph(InfrasonicModel, "ip");
    const hip = useGraph(InfrasonicModel, "hip");
    const satellite = useGraph(SatelliteModel, "mirova");
    const settings = useGraph(SystemModel, "settings");

    /*** NO NEED SYSTEM MODEL CALL HERE => POLLING DATA IS ALWAYS ACTIVE ***/
    useEffect(() => {
        let timer = -1;
        if (model) {
            model.read(SatelliteModel, m => m.Mirova());
            timer = model.read(InfrasonicModel, m => m.startCurvePolling())
        }
        return () => clearInterval(timer);
    }, [model]);

    return (
        <Vista context={ctx} >
            <Row style={{ height: '100%' }}>
                <Col sm={{ span: 8 }} xxl={{ span: 8 }}>
                    <MapView ip={mapip} source={system} mirova={satellite?.data} settings={settings.data} />
                </Col>
                <Col sm={{ span: 16 }} xxl={{ span: 16 }} style={{ height: 'calc(100vh - 108px)' }} >
                    <CameraView satellite={satellite} settings={settings.data} />
                    <InfrasonicBar />
                    <Row >
                        <Col span={24} style={{ height: 'calc( (100vh - 144px) - ((100vh - 108px)/5) )' }}>
                            <InfrasonicView ip={ip} hip={hip} />
                        </Col>
                    </Row>
                </Col>
            </Row>
        </Vista>
    )
}