import 'ol/ol.css';
import * as ol from "ol";
import OSM from 'ol/source/OSM';
import Static from 'ol/source/ImageStatic';
import View from 'ol/View';
import proj4 from 'proj4';
import { Tile as TileLayer, Vector } from 'ol/layer';
import VectorSource from 'ol/source/Vector';
import { Point } from 'ol/geom';
import { getCenter } from 'ol/extent';
import { register } from 'ol/proj/proj4';
import { useEffect, useRef } from 'react';
import ImageLayer2 from 'ol/layer/Image';
import { transformExtent, transform as transformp } from 'ol/proj';
import { useGraph } from '@essenza/react';
import { Fill, Style, Text } from "ol/style";
import { esat } from './radiativePower';

export function MapSatelliteView({ model, nodePath, initialData, children, refs, style }) {
  const settings = useGraph("system.settings").data;
  const source = useGraph("satellite.image");
  const data = source.data;
  const mapRef = useRef();
  const olmap = useRef();
  if (refs)
    refs.current = olmap.current;
  useEffect(() => {
    var map;

    const extent = transformExtent([14.7194241352622, 37.9589937690583, 15.2868561695722, 37.5083146151764], 'EPSG:4326', 'EPSG:3857');

    const il = new ImageLayer2({
      name: 'img',
      source: new Static({
        attributions: '© Mirova',
        url: data,
        projection: 'EPSG:3857', 
        imageExtent: extent,

      }), opacity: 0.8,
    });
    proj4.defs(
      'EPSG:27700',
      '+proj=tmerc +lat_0=49 +lon_0=-2 +k=0.9996012717 ' +
      '+x_0=400000 +y_0=-100000 +ellps=airy ' +
      '+towgs84=446.448,-125.157,542.06,0.15,0.247,0.842,-20.489 ' +
      '+units=m +no_defs'
    );
    register(proj4);

    const LabelLayer = new Vector({
      name: 'label',
      source: new VectorSource({
        features: [new ol.Feature({ geometry: new Point(transformp([parseFloat(14.7194241352622), parseFloat(37.9589937690583)], 'EPSG:4326', 'EPSG:3857')) })]
      }),
    });

    map = new ol.Map({
      layers: [
        new TileLayer({
          source: new OSM(),
        }),
        //imageLayer, 
        il, LabelLayer
      ],
      target: 'map',
      view: new View({
        projection: 'EPSG:3857',
        center: getCenter(extent),
        zoom: 9,
      }),
    });
    map.setTarget(mapRef.current);

    olmap.current = map;
    if (refs)
      refs.current = map;
    return () => map.setTarget(undefined);

  }, []);

  useEffect(() => {

    const map = olmap.current;
    if (refs)
      refs.current = map;
    if (map && data) {
      const layers = map.getLayers().getArray();
      const extent = transformExtent([14.7194241352622, 37.9589937690583, 15.2868561695722, 37.5083146151764], 'EPSG:4326', 'EPSG:3857');
      const sta = layers.find(layer => layer.get('name') === 'img');
      sta.setSource(new Static({
        attributions: '© Mirova',
        url: settings.UrlModis + data.impath,
        projection: 'EPSG:3857', 
        imageExtent: extent,
      }));

      if (data.time) {
        const labelStyle = [
          new Style({
            text: new Text({
              fill: new Fill({
                color: '#000',
              }),
              backgroundFill: new Fill({ color: 'hsla(0, 0%, 100%, 1)' }),
              text: new Date(data.time + 'Z').toLocaleString() + (data.values ? '\nVRP = ' + (data.values[esat.VRP] || 0) + 'MW\nZEN = ' + (data.values[esat.Zen] || 0) + '°\nAZI = ' + (data.values[esat.Azi] || 0) : ""),
              offsetX: 100,
              offsetY: -50,
              scale: 2,
            })
          })
        ];
        const label = layers.find(layer => layer.get('name') === 'label');
        label.setStyle(labelStyle);
      }

    }
  }, [data]);
  return <div ref={mapRef} className="map" style={style}></div>
}