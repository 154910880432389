import { ApiService } from "@essenza/core";

export function ChannelService() {
    ApiService.call(this);

    this.parameters = "/bandpass/0.5/10/True/1/0/False";

    this.getData = (channel, interval) => {
        return this.call(ChannelService.url + channel + "/" + interval.ts + "/" + interval.te + this.parameters, null, { method: 'get', crossdomain: true });
    };
}