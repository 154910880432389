import { Col, Row, Spin } from 'antd';
import { useEffect, useState } from 'react';

/**
 * 
 * @param {*} param0 
 * @param {object} data DrumplotData
* @returns 
 */
export function DrumplotView({ settings, station, data }) {
  const [loading, setLoading] = useState(true);

  useEffect(()=>{
    if (data) {
      setLoading(true);
    }
  }, [data])

  if(!station) return null;
  
  let span = station?.sensors && station.sensors.length>0 ? parseInt(24 / station.sensors.length) : 8;

  return (
    <>
      {loading && <div className='spin-container'><Spin /></div>}
        <Row style={{ height: '100%', visibility: loading?'hidden':'visible' }} >
          {data && station.sensors.map((sensor) =>
            <Col span={24} md={span}><h3 className='text-center'>{station.name} Sensor {sensor.name}</h3><img onLoad={() => setLoading(false)} alt='Sensore non disponibile' style={{ width: '100%' }} className="drumplot-img" src={settings.UrlDrumplot + "?ts=" + data.t.ts + "&te=" + data.t.te + "&trname=" + sensor.trace_id + "&fmin=" + (data.fmin || 1) + "&amp;fmax=" + (data.fmax || 20) + "&yr=" + (data.yr || sensor.yr || 1000) + "&unit=" + (sensor.unit || 'Pa')}></img></Col>
          )}
        </Row>
    </>
  )
}