import { useCallback, useRef } from 'react';
import { useControl } from "@essenza/react";

import EChart from '../../components/chart/chart';
import Title from '../../components/chart/option/title';
import { useEChart } from '../../hook/chartHook';
import Legend from '../../components/chart/option/legend';
import Tooltip from '../../components/chart/option/tooltip';
import TimeAxis from '../../components/chart/option/axis/TimeAxis';
import LogAxis from '../../components/chart/option/axis/LogAxis';
import Series from '../../components/chart/option/series/series';
import Bar from '../../components/chart/option/series/Bar';
import Scatter from '../../components/chart/option/series/Scatter';
import ZoomSlider from '../../components/chart/option/zoom/Slider';
import ZoomInside from '../../components/chart/option/zoom/Inside';
import Features from '../../components/chart/option/feature/features';
import Restore from '../../components/chart/option/feature/restore';
import ExportImage from '../../components/chart/option/feature/image';
import ExportCSV from '../../components/chart/option/feature/csv';
import XAxis from "../../components/chart/option/axis/xAxis";
import YAxis from "../../components/chart/option/axis/yAxis";
import DataZoom from "../../components/chart/option/zoom/dataZoom";
import Event from '../../components/chart/option/events/event';

export const esat = { Azi: '1', Dist: '5', Impath: '6', Utmx: '14', Utmy: '15', VRP: '16', Zen: '17' }

function RadiativePowerControl(c) {
    c.skin = RadiativePower;
    c.command = {
        MODIS: (chart, { data }) => {
            const p = data.parameters.batch[0];
            const info = chart.getOption().dataset[p.seriesIndex].source[p.dataIndexInside];
            if (info.values)
                info.impath = info.values[esat.Impath]
            if (info.impath)
                c.setSource("satellite.image", info);
        }
    }
}

export function RadiativePower({ source, ...rest }) {
    const [control] = useControl(RadiativePowerControl);
    const chart = useEChart(); //NOTE => EXTRA OPTION NOT WRAPPED CAN BE SET HERE!!!
    const style = useRef({ symbol: null, color: null }).current;
    style.symbol = (params) => source.data && params.dataIndex === source.data.length - 1 ? 'diamond' : 'circle';
    style.color = (params) => {
        const values = params.value.values;
        if (source.data && params.dataIndex === source.data.length - 1) return '#FF0000'
        else if (values[esat.Dist] > 5000) return '#000000'
        else return '#0000FF'
    }
    const onDataOver = useCallback((p, chart) => control.execute("MODIS", chart, { parameters: p }), []);
    return (
        <EChart chart={chart} source={source.data} dimensions={['time', 'value']} loading >
            <Event on="highlight" callback={onDataOver} />
            <Title left="left" text="Log Radiative Power MODIS" textStyle={{ fontSize: 15 }} />
            <Legend padding={7} data={['value']} />
            <Tooltip trigger="axis" formatter={(p) => { return '<span style="color: ' + p[1].color + ';">&#9679;</span> VRP: ' + p[1].value?.value }} />
            <XAxis>
                <TimeAxis />
            </XAxis>
            <YAxis>
                <LogAxis name="VRP (Watt)" nameLocation="middle" nameTextStyle={{ fontWeight: 'bold' }} />
            </YAxis>
            <DataZoom direction="x">
                <ZoomSlider start={0} end={100} />
                <ZoomInside start={0} end={100} />
            </DataZoom>
            <Series>
                <Bar name="VRP" barWidth={2} encode={{ x: 'Time', y: 'value' }} />
                <Scatter name="VRP" encode={{ x: 'Time', y: 'value' }} symbol={(value, params) => style.symbol(params)} itemStyle={{ color: (params) => style.color(params) }} />
            </Series>
            <Features>
                <Restore title="RIPRISTINA" />
                <ExportImage title="SALVA IMMAGINE" excludeComponents={['dataZoom', 'toolbox']} />
                <ExportCSV title="SCARICA DATI" />
            </Features>
        </EChart>
    )
}
