import { useCallback, useRef } from "react";
import {  useControl } from "@essenza/react";

import EChart from '../../components/chart/chart';
import { useEChart } from '../../hook/chartHook';
import Legend from '../../components/chart/option/legend';
import Tooltip from '../../components/chart/option/tooltip';
import TimeAxis from '../../components/chart/option/axis/TimeAxis';
import Series from '../../components/chart/option/series/series';
import ZoomSlider from '../../components/chart/option/zoom/Slider';
import ZoomInside from '../../components/chart/option/zoom/Inside';
import ValueAxis from '../../components/chart/option/axis/ValueAxis';
import { EWStyle } from "./EWStyle";
import Scatter from "../../components/chart/option/series/Scatter";
import XAxis from "../../components/chart/option/axis/xAxis";
import YAxis from "../../components/chart/option/axis/yAxis";
import Grid from "../../components/chart/option/grid/grid";
import GridItem from "../../components/chart/option/grid/griditem";
import DataZoom from "../../components/chart/option/zoom/dataZoom";
import Zoom from "../../components/chart/option/zoom/zoom";
import Event from "../../components/chart/option/events/event";
import { InfrasonicModel } from "../../models/InfrasonicModel";

function EWChartControl(c){
  c.skin = EWChart;
  c.command = {
    INFRASONIC: (chart, {data, model}) => {
      if (!data.station)
        return;

      const p = data.parameters.batch[0];
      const values = chart.getOption().dataset[p.seriesIndex].source[p.dataIndexInside];

      if (!data.waiting.current) {
        data.waiting.current = true;
        model.read(InfrasonicModel, m=>m.getCurve(data.station.name, values[0]));
        setTimeout(() => {
          data.waiting.current = false;
        }, 400);
      }
    }
  }
}

export function EWChart({ station, source }) {
  const [control] = useControl(EWChartControl);
  const waiting = useRef(false);
  const chart = useEChart({axisPointer: {
    link: [
      {
        xAxisIndex: 'all'
      }
    ]
  }});

  const onDataOver = useCallback((p, chart) => control.execute("INFRASONIC", chart, { parameters: p, station: station, waiting: waiting }), [station]);

  return (
    <EChart loading chart={chart} source={source.data?.values} dimensions={['Time', 'Azimuth', 'Coerenza', 'Pressione']} >
      <Event on="highlight" callback={onDataOver}/>
      <Legend selectedMode={false} data={['Azimuth', 'Coerenza', 'Pressione']} formatter={EWStyle.LegendFormat} />
      <Tooltip trigger="axis" axisPointer= {{animation: false, type: 'line', axis: 'x'}} formatter={EWStyle.TooltipFormat} />
      <Grid>
        <GridItem left='80' top='4%' right='8' height='25%'/>
        <GridItem left='80' bottom='41%' right='8' height='25%'/>
        <GridItem left='80' bottom='10%' right='8' height='25%'/>
      </Grid>
      <XAxis>
        <TimeAxis gridIndex={0} show={false} axisLabel={{formatter: EWStyle.dateFormat}}/>
        <TimeAxis gridIndex={1} show={false} axisLabel={{formatter: EWStyle.dateFormat}}/>
        <TimeAxis gridIndex={2} show={true} axisLabel={{formatter: EWStyle.dateFormat}}/>
      </XAxis>
      <YAxis>
        <ValueAxis name='Back-Azimuth (°N)' nameLocation='end' gridIndex={0} scale={true} nameTextStyle={EWStyle.nameFormat} />
        <ValueAxis name='Coherence (%)' nameLocation='end' gridIndex={1} scale={true} nameTextStyle={EWStyle.nameFormat} />
        <ValueAxis name='Pressure (Pa)' nameLocation='end' gridIndex={2} scale={true} nameTextStyle={EWStyle.nameFormat} />
      </YAxis>
      <DataZoom>
        <Zoom show={true}  realtime={false} xAxisIndex={[0, 1]} height={24} bottom= '1%' />
        <ZoomInside realtime={false} xAxisIndex={[0, 1]} left="1%" />
        <ZoomInside realtime={false} xAxisIndex={[0, 2]} />
        <ZoomSlider show={true}  realtime={false} yAxisIndex={[0]} left="8" />
        <ZoomSlider show={true}  realtime={false} yAxisIndex={[1]} left="1%" />
        <ZoomSlider show={true}  realtime={false} yAxisIndex={[2]} left="1%" />
        <ZoomInside  yAxisIndex={[0]} />
        <ZoomInside  yAxisIndex={[1]} />
        <ZoomInside  yAxisIndex={[2]}  />
      </DataZoom>
      <Series>
        <Scatter name='Azimuth'  symbolSize={6} xAxisIndex={0} yAxisIndex={0} encode={{ x: 'Time', y: 'Azimuth' }} />
        <Scatter name='Coerenza'  symbolSize={6} xAxisIndex={1} yAxisIndex={1} encode={{ x: 'Time', y: 'Coerenza' }} />
        <Scatter name='Pressione'  symbolSize={6} xAxisIndex={2} yAxisIndex={2} encode={{ x: 'Time', y: 'Pressione' }} />
      </Series>
    </EChart>
  )
}