import { Style, Icon, Stroke, Text } from 'ol/style';
import Fill from "ol/style/Fill";
import sensorIcon from "../../../assets/img/sensor.svg";
import sensorGrayIcon from "../../../assets/img/sensorgray.svg";

const SensorStyle = (feature) => {
    return new Style({
        image: new Icon({
          anchor: [0.5, 1],
          src: feature.get('status') === "0" ? sensorGrayIcon : sensorIcon,
          scale: 0.7,
        }),
        text: new Text({
          font: 'bold 14px Arial',
          text: feature.get('name'),
          fill: new Fill({ color: 'black' }),
          stroke: new Stroke({ color: 'black', width: 0.5 }),
          offsetY: -22, offsetX: 16,
        })
  
      })
}

export default SensorStyle;