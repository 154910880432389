import { Col, Row, Select, Button } from "antd";
import { useEffect, useState } from "react";
import { DrumplotInterval } from "../../core/utils";
import { useControl, useModel } from "@essenza/react";

const { Option } = Select;

function DPBarController(c) {
    c.skin = DrumplotBar;
    c.command = {

        DATE_CHANGE: (date, { model, data }) => {

            date.setHours(date.getHours() + data.interval);

            if (date.getTime() > new Date().getTime()) {
                model.setDate(new Date());
            }
            else {
                const dpdata = model.getGlobal("DrumplotData");
                dpdata.t = DrumplotInterval(Math.abs(data.interval), 0, new Date(date));
                model.setGlobal("DrumplotData", { ...dpdata });
            }
        },

        TIME_CHANGE: (value, { model }) => {
            model.setInterval(value);
            model.setDate(new Date());
            const dpdata = model.getGlobal("DrumplotData");
            dpdata.t = DrumplotInterval(value);
            model.setGlobal("DrumplotData", { ...dpdata })
        }
    }
}

export function DrumplotBar({ station }) {
    const [control] = useControl(DPBarController);
    const [model] = useModel();
    const [interval, setInterval] = useState(6);
    const [date, setDate] = useState(new Date());

    useEffect(() => {
        model.setInterval = setInterval;
        model.setDate = setDate;
    }, [model])

    if (!station) return null;

    return (
        <Row >
            <Col flex="none">
                <Button onClick={() => control.execute("DATE_CHANGE", date, { station: { ...station }, interval: -interval }, model)}>{'<'}</Button>
            </Col>
            <Col flex="none" >
                <Button className="ml-2" onClick={() => control.execute("DATE_CHANGE", date, { station: { ...station }, interval: interval }, model)}>{'>'}</Button>
            </Col>
            <Col className="ml-2" flex="none">
                <Button type="link" href="#" onClick={() => model.setGlobal("DrumplotFilter", !model.getGlobal("DrumplotFilter"))}>Filtri</Button>
            </Col>
            <Col flex="none">
                <Select defaultValue="6" style={{ width: 160 }} onChange={(v) => control.execute("TIME_CHANGE", v, { ...station }, model)} bordered={false}>
                    <Option value="6">Last 6H</Option>
                    <Option value="12">Last 12H</Option>
                    <Option value="24">Last 24H</Option>
                </Select>
            </Col>
        </Row>
    )
}