const LegendLabel = { Azimuth: 'Back Azimhut', Coerenza: 'Coherence', Pressione: 'Pressure' }

export const EWStyle = {
    dateFormat: (value, index) => new Date(value).getUTCHours() + ':00',

    LegendFormat: function (name) {
        return LegendLabel[name];
    },

    TooltipFormat: (params) => {
        const info = {};
        info[params[0].seriesName] = params[0];
        info[params[1].seriesName] = params[1];
        info[params[2].seriesName] = params[2];
        return `
              <b>${new Date(params[0].axisValue).toUTCString()}</b> <br />
             ${info.Azimuth.marker} ${LegendLabel[info.Azimuth.seriesName]}: ${info.Azimuth.value[1]}<br />
              ${info.Coerenza.marker} ${LegendLabel[info.Coerenza.seriesName]}: ${info.Coerenza.value[2]}<br />
              ${info.Pressione.marker} ${LegendLabel[info.Pressione.seriesName]}: ${info.Pressione.value[3]}
              `;
      },

      nameFormat: { align: 'left', fontWeight: 'bold', padding: [0, 0, -10, 0] }
}