import { DataModel } from "@essenza/core";

export function InfrasonicModel() {
    DataModel.call(this, "infrasonic");

    this.startCurvePolling = function () {
        let dt = new Date();
        let s = dt.getSeconds();
        dt.setSeconds(parseInt(s / 5) * 5);
        dt.setMinutes(dt.getMinutes() - 2);
        const instance = this;
        return setInterval(() => {
            dt.setTime(dt.getTime() + 5000);
            instance.ExecuteApi("jicurveat: infrasonic{geom, value}", { time: dt.toISOString().substring(0, 19) });
        }, 5000);
    }

    this.getData = function(interval){
        this.ExecuteApi("ip: infrasonic {*}", interval);
    }

    this.getHistoricData = function(interval){
        this.ExecuteApi("hip: infrasonic {*}", interval, "ip");
    }

    this.getCurve = function(name, date){
        console.log("STATION-NAME", name);
        this.ExecuteApi("jicurve: infrasonic {geom, value}", { station: name, time: new Date(date).toISOString().substring(0, 19) });
    }
}