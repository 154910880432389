export const StatusStyle =
{
    height: 140,
    width: 180,
    type: 'gauge',
    startAngle: 180,
    endAngle: 0,
    min: 0,
    max: 100,
    splitNumber: 1,
    center: ['50%', '40%'],
    itemStyle: {
        //color: '#58D9F9',
        shadowColor: 'rgba(0,138,255,0.45)',
        shadowBlur: 10,
        shadowOffsetX: 2,
        shadowOffsetY: 2
    },
    progress: {
        show: true,
        roundCap: true,
        width: 8
    },
    pointer: {
        icon: 'path://M2090.36389,615.30999 L2090.36389,615.30999 C2091.48372,615.30999 2092.40383,616.194028 2092.44859,617.312956 L2096.90698,728.755929 C2097.05155,732.369577 2094.2393,735.416212 2090.62566,735.56078 C2090.53845,735.564269 2090.45117,735.566014 2090.36389,735.566014 L2090.36389,735.566014 C2086.74736,735.566014 2083.81557,732.63423 2083.81557,729.017692 C2083.81557,728.930412 2083.81732,728.84314 2083.82081,728.755929 L2088.2792,617.312956 C2088.32396,616.194028 2089.24407,615.30999 2090.36389,615.30999 Z',
        length: '75%',
        width: 16,
        offsetCenter: [0, '5%']
    },

    title: {
        fontSize: 12, color: '#000',
    },
    detail: {
        backgroundColor: '#000',
        borderColor: '#999',
        borderWidth: 0,
        width: '30%',
        lineHeight: 30,
        height: 30,
        borderRadius: 8,
        offsetCenter: [0, '35%'],
        valueAnimation: true,
        formatter: function (value) {
            return '{value|' + value.toFixed(0) + '}{unit|%}';
        },
        rich: {
            value: {
                fontSize: 16,
                fontWeight: 'bolder',
                color: '#fff'
            },
            unit: {
                fontSize: 12,
                color: '#999',
                padding: [0, 0, -20, 0]
            }
        }
    },
}

export const StatusData = {
    value: [
        {
          data: [
            {
              value: 100,
              name: 'processed',
              title: {
                offsetCenter: ['-40%', '80%']
              },
              detail: {
                offsetCenter: ['-40%', '30%']
              }
            },
            {
              value: 70,
              name: 'trasmissed',
              title: {
                offsetCenter: ['40%', '80%']
              },
              detail: {
                offsetCenter: ['-40%', '80%']
              }
            },
          ]
        },
      ],

    format: (data) => { return {
        series: [{
          data: [
            {
              value: data.processed,
              name: 'Data Processed',
              title: {
                offsetCenter: ['22%', '50%'], backgroundColor: '#33cc33',
              },
              detail: {
                offsetCenter: ['-110%', '50%']
              },
              itemStyle: {
                color: '#33cc33',
                shadowColor: 'rgba(0,138,255,0.45)',
                shadowBlur: 10,
                shadowOffsetX: 2,
                shadowOffsetY: 2
              },
              pointer: {
                itemStyle: { color: '#33cc33' }
              }
            },
            {
              value: data.trasmissed,
              name: 'Data Trasmitted',
              title: {
                offsetCenter: ['22%', '120%'], backgroundColor: '#ff9900',
              },
              detail: {
                offsetCenter: ['-110%', '120%']
              },
              itemStyle: {
                color: '#ff9900',
                shadowColor: 'rgba(0,138,255,0.45)',
                shadowBlur: 10,
                shadowOffsetX: 2,
                shadowOffsetY: 2
              },
              pointer: {
                itemStyle: { color: '#ff9900' }
              }
            },
          ]
        },
        ]
      }}
}