import { Control as olcontrol } from 'ol/control';
import Control from '../../../components/map/control/Control';


class LayerControlClass extends olcontrol {

    /**
     * @param {Object} [opt_options] Control options.
     */
    constructor(opt_options) {
        const options = opt_options || {};

        const group = document.createElement('div');
        group.className = 'row layer-group ';

        super({
            element: group,
            target: options.target,
        });

        let layers = null;

        if (options.layers) {
            layers = options.layers
        }

        const left = options.marginLeft || 0.5;

        if (layers && Array.isArray(layers)) {
            let layer, icon, element, button, item;

            for (let k = 0; k < layers.length; k++) {
                layer = layers[k];

                icon = document.createElement('img');
                icon.className = 'layer-sw-icon';
                icon.setAttribute("src", layer.icon);

                element = document.createElement('div');
                element.className = 'layer-icon-container  ';
                element.appendChild(icon);

                button = document.createElement('button');
                button.style.setProperty("left", (left + k * (options.gap || 5)) + 'em')
                button.className = 'layer-icon ol-unselectable ol-control';
                button.dataset.id = layer.name;
                button.appendChild(element);

                button.addEventListener('click', this.switchLayer.bind(this), false);

                item = document.createElement('div');
                item.className = 'col';
                item.appendChild(button);

                group.appendChild(item);
            }
        }
    }

    switchLayer(e) {
        const id = e.currentTarget.dataset.id;
        const map = this.getMap();
        if (!map) return;
        const layer = this.getMap().getLayers().getArray().find(l => l.get('name') === id);
        if (layer) {
            if (layer.getVisible()) {
                e.currentTarget.style.setProperty("background-color", "transparent");
                layer.setVisible(false);
            }
            else {
                e.currentTarget.style.setProperty("background-color", "#FFFFFF");
                layer.setVisible(true);
            }
        }
    }
}

export function LayerControl(props) {
    return <Control olcontrol={LayerControlClass}  {...props} />
}