import { Col, Row, Menu, Tabs } from "antd";
import { useEffect, useRef, useState } from "react";
import { adjustParams, DrumplotInterval } from "../core/utils";
import { Polling } from "@essenza/core";
import { Vista, useVista, useModel, useGraph, useGlobal } from "@essenza/react";
import { StatusBar } from "../view/station/status";
import { InfrasonicLocation, InfrasonicMap } from "../view/station/EWMap";
import { EWChart, EWCharts } from "../view/station/EWChart";
import { InfrasonicModel } from "../models/InfrasonicModel";
import { StationModel } from "../models/StationModel";
import { DrumplotView } from "../view/station/Drumplot";
import { DrumplotBar } from "../view/station/DrumplotBar";
import { SystemModel } from "../models/model";
import { DrumplotFilter } from "../view/station/DrumplotForm";
import { EWBar } from "../view/station/EWBar";

const { TabPane } = Tabs;

function StationVistaController(c) {
    c.skin = StationVista;
    c.command = {
        CHANGE: (station, { model, data }) => {
            data.dp.t = DrumplotInterval(6);
            model.setDrumplotData({ ...data.dp });
            model.setStation(station);
            data.current.station = station;
            model.read(StationModel, m => m.GetData(adjustParams(station, data.time)));
        }
    }
}

export default function StationVista(props) {
    const [ctx, control] = useVista(StationVistaController);

    /* MODEL */
    const [model] = useModel();
    let stations = useGraph(SystemModel, "stations").data;
    const ip = useGraph(InfrasonicModel, "jicurve");
    const stationdata = useGraph(StationModel, "jstationdata");
    const settings = useGraph(SystemModel, "settings");

    /* STATE */
    const [dpdata, setDrumplotData] = useGlobal("DrumplotData", { t: DrumplotInterval(6) });
    const [timeInterval] = useGlobal("station.timeinterval", { offset: 24, te: new Date() });
    const [fvisibile] = useGlobal("DrumplotFilter", false);
    const [tabIndex, setTabIndex] = useState(1);
    const [station, setStation] = useState(); //CURRENT STATION

    /* REF */
    const initialized = useRef(false);
    const menuItems = useRef();
    const data = useRef({ time: timeInterval });

    useEffect(() => {
        data.current.time = timeInterval;
    }, [timeInterval]);

    useEffect(() => {
        if (!initialized.current && stations && stations.length > 0) {

            menuItems.current = stations.map((s, ind) => {
                return { label: s.name, key: ind, };
            });

            data.current.station = stations[0];

            new Polling(() => {
                data.current.time.te = new Date();
                model.read(StationModel, m => m.GetData(adjustParams(data.current.station, data.current.time)));
            }).atSecond(10).start();

            initialized.current = true;

            setStation(stations[0]);

            model.setStation = setStation;
            model.setDrumplotData = setDrumplotData;
        }
    }, [stations, model]);

    const onTabChange = (index) => {
        if (index === "0" && station) { //RESET DRUMPLOT INTERVAL
            setDrumplotData({ ...dpdata, t: DrumplotInterval(6) })
        }
        setTabIndex(index);
    }

    return (
        <Vista context={ctx} >
            <Row className="station-vista" justify="center" wrap={false} style={{ height: 'calc(100vh - 172px)', backgroundColor: '#E9EDF5' }}>
                <Col flex="240px" className="menu-container scroll  pt-2" style={{ backgroundColor: '#000', height: 'calc(100vh - 108px)', overflowY: 'auto', overflowX: 'hidden' }}>
                    <Menu mode="horizontal" onSelect={(item) => control.execute("CHANGE", stations[item.key], { dp: dpdata, time: timeInterval, current: data }, model)} defaultSelectedKeys="0" items={menuItems.current} />
                    <StatusBar source={stationdata} />
                </Col>
                <Col flex="auto" >
                    <Tabs tabBarExtraContent={{ right: [<DrumplotBar station={station} />, <EWBar station={station} />, null][tabIndex] }} onChange={onTabChange} defaultActiveKey="1" type="line" tabPosition="top" tabBarStyle={{ backgroundColor: '#E9EDF5', marginBottom: '0px', }} style={{ height: '100%' }}>
                        <TabPane tab="Drumplot" key="0">
                            <Row style={{ backgroundColor: "#000", padding: "8px" }}>
                                {fvisibile ? <DrumplotFilter station={station} /> : null}
                            </Row>
                            <DrumplotView settings={settings.data} station={station} data={dpdata} />
                        </TabPane>
                        <TabPane tab="Detections" key="1">
                            <Row style={{ height: '100%' }}>
                                <Col span={8} style={{ height: 'calc(100vh - 204px)' }}>
                                    <InfrasonicMap ip={ip} station={station} />
                                </Col>
                                <Col className="border-round ew-container" style={{ height: 'calc(100vh - 154px)' }} span={16}>
                                    <EWChart station={station} source={stationdata} />
                                </Col>
                            </Row>
                        </TabPane>
                    </Tabs>
                </Col>
            </Row>
        </Vista>
    )
}