import { Control as olcontrol } from 'ol/control';
import { useEffect, useRef } from 'react';
import Control from '../../../components/map/control/Control';
import { Style } from 'ol/style';
export class MapElementSwitchControl extends olcontrol {
  constructor(opt) {
    const options = opt || {};
    const group = document.createElement('div');
    group.className = opt.conteinerStyle || 'map-switcher ';
   
    super({
      element: group,
      target: options.target,
    });

    const elements = opt.elements;
    this.token = opt.token;
    this.initialized = false;
    this.layer = options.layer || "stations";
    this.childLayer = options.childLayer || "sensors";
    this.visible = options.visibility;
    this.m = options.map;
    this.Init(elements, opt);
  }

  Init(elements, option) {
    if ( elements && Array.isArray(elements)) {
      option = option || {};
      let cb; let el; let label;
      while (this.element.firstChild) {
        this.element.firstChild.remove();     
      }
      for (let k = 0; k < elements.length; k++) {
        el = elements[k];
        cb = document.createElement("input");
        cb.setAttribute("type", "checkbox");
        cb.setAttribute("value", el.name); //el.name
        cb.setAttribute("id", el.name);
        cb.checked = this.visible && this.visible.hasOwnProperty(el.name)? this.visible[el.name] : true;
        cb.addEventListener('change', this.onChange.bind(this), false);
        cb.className = option.checkboxClassName || 'map-checkbox ';
        this.element.appendChild(cb);

        label = document.createElement("label");
        label.setAttribute("for", el.name);
        label.textContent = el.name;
        label.className = option.labelClassName || 'map-label ';
        this.element.appendChild(label);

        this.element.appendChild(document.createElement("br"));

        if(!cb.checked){
          this.setVisibility(el.name, false);
        }
      }
    }
  }

  onChange(e) {
    this.setVisibility(e.target.value, e.target.checked)
  }

  setVisibility(name, visible, key="name", layername) {
    layername = layername || this.layer;
    const map = this.getMap() || this.m;
    
    if(!map) return;
    this.visible[name] = visible;
    const layer = map.getLayers().getArray().find(l => l.get('name') === layername);
    if (layer) {
      const source = layer.getSource();
      source.forEachFeature((f)=>{
        if (f.get(key) === name) {
          if(visible)
            f.setStyle();
          else
            f.setStyle(new Style({}));

          if(this.childLayer)
            this.setChildVisibility(name, visible, "sname", this.childLayer);
          
          return true;
        }
      })
    }
  }

  setChildVisibility(name, visible, key="sname", layername) {
    const map = this.getMap();
    if(!map) return;
    const layer = map.getLayers().getArray().find(l => l.get('name') === layername);
    if (layer) {
      const source = layer.getSource();
      source.forEachFeature((f)=>{
        if (f.get(key) === name) {
          if(visible)
            f.setStyle();
          else
            f.setStyle(new Style({}));
        }
      })
    }
  }
}

export function ElementSwitch(props) {
  const ref = useRef();

  useEffect(() => {
    if (ref.current && props.elements) {
      ref.current.Init(props.elements);
    }
  }, [props.elements]);

  return <Control refs={ref} olcontrol={MapElementSwitchControl}  {...props} />
}